import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import HeroBanner from "../components/support/herobanner";
import Features from "../components/support/features";

const ogImage = {
  ogImage:
    "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677773175/main-web/support_yi2t2p.jpg",
};

const canonicalUrl = "https://hasura.io/support/";

const Support = props => (
  <Layout location={props.location}>
    <Seo
      title="Hasura Support Plans"
      description="At Hasura, our aim is to ensure success for our customers. Our support plans are tailored to provide you with an optimal blend of production and developer support, resources and expert guidance, enabling you to achieve success with Hasura Enterprise."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <HeroBanner />
    <Features location={props.location} />
  </Layout>
);

export default Support;
